import currencyData from 'json/currency.json'

export default function PriceFunction({
  currency,
  value,
  location,
  small,
  discount
}) {
  let currencies={
    id:'IDR',
    en:'MYR',
    sg:'SGD',
    pk:'PKR'
  }

  let formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencies[currency]
  }).format(value).replace(currencies[currency], '').trim()
  // let formattedValue=Number(value).toFixed(2)
  return (
    <>
      { location==='bottom'? 
      <>
      <div className="flex-box jc-c">
        <div className="new">
          <span className="currency text-white">{currencyData[currency]}</span>
          <span id="value1" className="value priceValue text-white"> 
          {formattedValue}
          </span>
        </div>
      </div>
    </>
      :
      <>
      <sup className={small?'currency text-grey-2 fs-04 fw-800 ':"currency"}>
        {currencyData[currency]}
      </sup>
      <span className={small?'text-grey-2 fs-06 fw-800':"value"}>
        {formattedValue}
      </span>
    </>

      }
    </>
  )
}
