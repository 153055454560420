const newUrlWithQueryParam = (originalUrl, queryParamKey, queryParamValue) => {
  const url = new URL(originalUrl)
  url.searchParams.set(queryParamKey, queryParamValue)
  return url.toString()
}

const fullUrlFromRequest = (req) => {
  return req.protocol + '://' + req.get('host') + req.originalUrl
}

const isValidSlugPageNumber = (slug, page, pageNumber, optionalCheck = null) => {
  if (!slug) {
    return false
  }
  if (page && page !== 'page') {
    return false
  }
  if (page && isNaN(pageNumber)) {
    return false
  }

  if (optionalCheck && typeof optionalCheck === 'function' && !optionalCheck()) {
    return false
  }

  return true
}

// this function takes the url/i18n locale and returns the locale that is used in the database
const localeMapper = (urlLocale) => {
  if (!urlLocale) {
    return {
      i18nLocale: 'en',
      locale: 'en',
      language: 'en'
    }
  }

  switch (urlLocale) {
    case 'en':
    case 'en-US':
    case 'en-MY':
    case 'en-ID':
      return {
        i18nLocale: 'en',
        locale: 'en',
        language: 'en'
      }
    case 'id':
    case 'id-ID':
      return {
        i18nLocale: 'id-ID',
        locale: 'id',
        language: 'id'
      }
    case 'sg':
    case 'en-SG':
      return {
        i18nLocale: 'en-SG',
        locale: 'sg',
        language: 'en'
      }
    default:
      return {
        i18nLocale: urlLocale,
        locale: urlLocale.split('-')[0]
      }
  }
}

function reverseTransformation(str) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()).replaceAll('-', ' ');
}

function servicePackageUrlStructure({package,store,locale,mode=null,category,page}) {
  
  let storeLocation=store?.address?.state?.toLowerCase().replace(/[^\w\s']/gi,' ').replace("'", "").toLowerCase().trim().split(' ').join('-').replace(/-+/g,'-')
  let storeName=store?.combined_uuid_city
  let serviceCategory=package?.product_category?
    package?.product_category.name?.replace(/[^\w\s']/gi,' ').replace("'", "").toLowerCase().trim().split(' ').join('-').replace(/-+/g,'-')
    : 
    package?.categories[0]?.name?.replace(/[^\w\s']/gi,' ').replace("'", "").toLowerCase().trim().split(' ').join('-').replace(/-+/g,'-') ||null
  
  let serviceName=package?.uuid ||null
  let str
  if(mode=='service'){
    str=(locale==='en'?'':"/"+locale)+
    "/health-services/" + 
    `${storeLocation}/` +
    `clinics-hospitals/` + 
    `${storeName}/` + 
    `${serviceCategory}/` + 
    serviceName
  }else if(mode==='store'){
    str=(locale==='en'?'':"/"+locale)+
    "/health-services/" + 
    `${storeLocation}/` +
    `clinics-hospitals/` +
    `${storeName}` 
    if(page){
      str+=`/page/${page}`
    }
  }
  else if(mode==='category'){
    str=(locale==='en'?'':"/"+locale)+
    "/health-services/" + 
    `${storeLocation}/` +
    `clinics-hospitals/` +
    `${storeName}/` +
    `${category}` 
    if(page){
      str+=`/page/${page}`
    }
  }

  return str
}


module.exports = { newUrlWithQueryParam, fullUrlFromRequest, isValidSlugPageNumber, localeMapper,reverseTransformation,servicePackageUrlStructure }
